import { gql } from 'apollo-boost/lib/index'
import { buildQuery, mutateFunction } from '../common/Utils'
import {
  Query,
  UserBillingHistoriesArgs,
  UpdateUserInput,
  Mutation,
  UserSearchHistoriesArgs,
  QueryGetAllUsersArgs,
  RatingInput,
} from '../gen/Models'
import { PAGE_INFO_FRAGMENT } from './Fragment'

const USER_FRAGMENT = gql`
  fragment User on User {
    id
    firstName
    lastName
    email
    signupCode
    organization
    billing {
      id
      searchCount
      searchLimit
      expireTime
    }
    role
    files {
      data {
        id
        name
        url
        numLines
        numMatchedLines
      }
    }
    bulkAccess
    constructAccess
  }
`

const USER_RATING_FRAGMENT = gql`
  fragment UserRating on UserRating {
    id
    rating
    type
    context
  }
`

export const ME_QUERY = gql`
  query Me {
    me {
      ...User
      lastLogin
      createdAt
    }
  }
  ${USER_FRAGMENT}
`

// prettier-ignore
export const MeData = buildQuery<Pick<Query, 'me'>>({
  query: ME_QUERY,
})

// prettier-ignore
export const updateMeMutation = mutateFunction<Pick<Mutation, 'updateMe'>,
{ model: UpdateUserInput }
>({
  mutation: gql`
    mutation updateMe($model: UpdateUserInput!) {
      updateMe(model: $model) {
        id
        firstName
        lastName
      }
    }
  `,
})

const BILLING_HISTORY_FRAGMENT = gql`
  fragment BillingHistory on BillingHistory {
    id
    createdAt
    expireTime
    searchLimit
    searchCount
    data
  }
`

// prettier-ignore
export const BillingHistoriesData = buildQuery<Pick<Query, 'me'>, UserBillingHistoriesArgs>({
  query: gql`
    query BillingHistories($pagination: Pagination) {
      me {
        id
        billingHistories(pagination: $pagination) {
          data {
            ...BillingHistory
          }
          info {
            ...PageInfo
          }
        }
      }
    }
    ${BILLING_HISTORY_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
  `,
})

const SEARCH_HISTORY_FRAGMENT = gql`
  fragment SearchHistory on SearchHistory {
    id
    searchText
    supplier
    description
    partialNumber
    uom
    totalResults
    segment
    subSegment
    gic
    gicType1
    gicType2
    productLine
    productCategory
    clinicalCategory
    gmdn
    gudid
    type
    exact
  }
`

// prettier-ignore
export const SearchHistoriesData = buildQuery<Pick<Query, 'me'>, UserSearchHistoriesArgs>({
  query: gql`
    query SearchHistories($pagination: Pagination) {
      me {
        id
        searchHistories(pagination: $pagination) {
          data {
            ...SearchHistory
          }
          info {
            ...PageInfo
          }
        }
      }
    }
    ${SEARCH_HISTORY_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
  `,
  displayName: 'SearchHistory',
})

// prettier-ignore
export const AllUsersData = buildQuery<Pick<Query, 'getAllUsers'>, QueryGetAllUsersArgs>({
  query: gql`
    query GetAllUsers($query: UserQueryInput) {
      getAllUsers(query: $query) {
        users {
          ...User
        }
        info {
          ...PageInfo
        }
      }
   }
   ${USER_FRAGMENT}
   ${PAGE_INFO_FRAGMENT}
 `,
})

// prettier-ignore
export const manageUserInfo = mutateFunction<Pick<Mutation, 'manageUserInfo'>,
{ userId: number, model: UpdateUserInput }
>({
  mutation: gql`
    mutation manageUserInfo($userId: Int!, $model: UpdateUserInput!) {
      manageUserInfo(model: $model, userId: $userId) {
        id
        firstName
        lastName
      }
    }
  `,
})

export const toggleBulkAccess = mutateFunction<
  Pick<Mutation, 'toggleBulkAccess'>,
  { userId: number }
>({
  mutation: gql`
    mutation toggleBulkAccess($userId: Int!) {
      toggleBulkAccess(userId: $userId) {
        ...User
      }
    }
    ${USER_FRAGMENT}
  `,
})

export const toggleConstructAccess = mutateFunction<
  Pick<Mutation, 'toggleConstructAccess'>,
  { userId: number }
>({
  mutation: gql`
    mutation toggleConstructAccess($userId: Int!) {
      toggleConstructAccess(userId: $userId) {
        ...User
      }
    }
    ${USER_FRAGMENT}
  `,
})

export const unblockUserFiles = mutateFunction<
  Pick<Mutation, 'unblockUserFiles'>,
  { userId: number }
>({
  mutation: gql`
    mutation toggleBulkAccess($userId: Int!) {
      unblockUserFiles(userId: $userId)
    }
  `,
})

export const addRating = mutateFunction<Pick<Mutation, 'addRating'>, { model: RatingInput }>({
  mutation: gql`
    mutation addRating($model: RatingInput!) {
      addRating(model: $model) {
        ...UserRating
      }
    }
    ${USER_RATING_FRAGMENT}
  `,
})

export const requestConstructAccess = mutateFunction<Pick<Mutation, 'requestConstructAccess'>>({
  mutation: gql`
    mutation requestConstructAccess {
      requestConstructAccess
    }
  `,
})
