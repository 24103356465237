import type { TableProps } from 'antd'
import { Empty, Flex, Table } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { ClinicalStudy } from '../../api'
import { FetchClinicalStudiesData } from '../../api/components/Search'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import { SummaryTitle } from './SummaryItem'

type Props = {
  gudid?: string | null
}

const columns: TableProps<ClinicalStudy>['columns'] = [
  {
    title: 'NCT#',
    dataIndex: 'nctId',
    render: nctId => (
      <a href={`https://beta.clinicaltrials.gov/study/${nctId}`} target="_blank">
        {nctId}
      </a>
    ),
  },
  {
    title: 'Official Title',
    dataIndex: 'officialTitle',
    render: v => <TextCell>{v}</TextCell>,
  },
  {
    title: 'Completion Date',
    dataIndex: 'completionDate',
  },
  {
    title: 'Study Type',
    dataIndex: 'studyType',
  },
  {
    title: 'Sponsor',
    dataIndex: 'leadSponsorName',
  },
]

export const ClinicalStudyDetail: React.FC<Props> = ({ gudid }) => {
  const [pagination, setPagination] = useState<{ offset: number; limit: number }>({
    offset: 0,
    limit: 50,
  })
  return (
    <FetchClinicalStudiesData variables={{ input: { gudid }, pagination }} skip={!gudid}>
      {({ data, loading }) => {
        if (loading) {
          return (
            <LoadingWrapper>
              <LoadingIndicator color={Colors.primary} />
            </LoadingWrapper>
          )
        }

        if (!data?.fetchClinicalStudies?.data.length) {
          return <></>
        }
        return (
          <ClinicalWrapper>
            <SummaryTitle>Relevant Clinical Studies</SummaryTitle>
            <Table
              loading={loading}
              size="small"
              columns={columns}
              dataSource={data?.fetchClinicalStudies?.data || []}
              locale={{
                emptyText: <Empty description="No clinical study available" />,
              }}
              pagination={{
                total: data?.fetchClinicalStudies?.total || 0,
                current: pagination.offset ? pagination.limit / pagination.offset + 1 : 1,
                pageSize: pagination.limit,
                showTotal: t => `${t} items`,
                totalBoundaryShowSizeChanger: 10,
                hideOnSinglePage: true,
                onChange: (page: number, pageSize: number) => {
                  setPagination({ offset: (page - 1) * pageSize, limit: pageSize })
                },
              }}
            />
          </ClinicalWrapper>
        )
      }}
    </FetchClinicalStudiesData>
  )
}

const TextCell = styled.span`
  white-space: break-spaces;
  word-wrap: break-word;
`

const ClinicalWrapper = styled.div`
  width: 100%;
  padding: 10px 4px 4px 4px;
`

const LoadingWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#f2f4f7', 0.5)};
  justify-content: center;
  align-items: center;
`
