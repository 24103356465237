import { gql } from 'apollo-boost/lib/index'
import { buildQuery, mutateFunction } from '../common/Utils'
import {
  Query,
  QuerySearchFilterArgs,
  QuerySearchArgs,
  QuerySuggestionArgs,
  Mutation,
  BadDataReportInput,
  FalseNegativeInput,
  QueryFetchFdaDataArgs,
  MutationRemoveRecentSearchArgs,
  QueryFetchClinicalStudiesArgs,
} from '../gen/Models'

export const SearchFilterData = buildQuery<Pick<Query, 'searchFilter'>, QuerySearchFilterArgs>({
  query: gql`
    query searchFilter($query: SearchQueryInput, $strict: Boolean) {
      searchFilter(query: $query, strict: $strict) {
        supplier {
          name
          total
        }
        uom {
          name
          total
        }
        segment {
          name
          total
        }
        subSegment {
          name
          total
        }
        gic {
          name
          total
        }
        gicType1 {
          name
          total
        }
        gicType2 {
          name
          total
        }
        productLine {
          name
          total
        }
        productCategory {
          name
          total
        }
        clinicalCategory {
          name
          total
        }
        gmdn {
          name
          total
        }
        totalResults
      }
    }
  `,
})

// prettier-ignore
export const SearchFilterTotalResultsData = buildQuery<Pick<Query, 'searchFilter'>, QuerySearchFilterArgs>({
  query: gql`
    query SearchFilterTotalResults($query: SearchQueryInput) {
      searchFilter(query: $query) {
        totalResults
      }
    }
  `,
})

export const SearchData = buildQuery<Pick<Query, 'search'>, QuerySearchArgs>({
  query: gql`
    query search($query: SearchQueryInput!, $pagination: Pagination, $sort: [Sort]) {
      search(query: $query, pagination: $pagination, sort: $sort) {
        data {
          id
          transactionsList {
            tsId
            facilityid
            datepurchase
            qtypurchase
            unitprice
          }
          matchId
          conversion
          description
          supplier {
            name
            normalized
          }
          product {
            number
            stripped
            uom
          }
          benchmark {
            iqo25
            iqo50
            iqo75
            iqo90
            averagePrice
            totalQuantity
            hospitalCount
            totalSpend
            priceSpray {
              facilityid
              priceSpray {
                unitprice
                totalQtyPurchase
              }
            }
          }
          gudid {
            gudid
            gmdnName
            catalogNumber
            versionModelNumber
            submissionNumbers
            companyName
          }
          med {
            unspsc
            unspscInfo {
              segmentTitle
              familyTitle
              classTitle
              commodityTitle
            }
          }
          trends {
            priceTrend
            volumeTrend
          }
          stan {
            gicId
            gicName
            segment
            subSegment
            typeOne
            typeTwo
            material
          }
          curvo {
            productCategory
          }
        }
        info {
          offset
          total
        }
      }
    }
  `,
})

export const TRANSACTION_FRAGMENT = gql`
  fragment Transaction on Transaction {
    facilityid
    datepurchase
    qtypurchase
    unitprice
  }
`

export const SuggestionData = buildQuery<Pick<Query, 'suggestion'>, QuerySuggestionArgs>({
  query: gql`
    query suggestion($searchText: String!, $searchType: SearchTypeEnum!) {
      suggestion(searchText: $searchText, searchType: $searchType) {
        text
        suggestions {
          groupType
          data {
            name
            supplier
            uom
            asp
            benchmark {
              hospitalCount
            }
          }
        }
      }
    }
  `,
})

// prettier-ignore
export const sendBadDataReportMutation = mutateFunction<
Pick<Mutation, 'sendBadDataReport'>,
{ data: BadDataReportInput }
>({
  mutation: gql`
    mutation sendBadDataReport($data: BadDataReportInput!) {
      sendBadDataReport(data: $data) 
    }
  `,
})

// prettier-ignore
export const createFalseNegativeReportMutation = mutateFunction<
Pick<Mutation, 'createFalseNegativeReport'>,
{ data: FalseNegativeInput[] }
>({
  mutation: gql`
    mutation createFalseNegativeReport($data: [FalseNegativeInput!]!) {
      createFalseNegativeReport(data: $data)
    }
  `,
})

// prettier-ignore
export const requestBulkAccessMutation = mutateFunction<Pick<Mutation, 'requestBulkAccess'>, { fileId: number }>({
  mutation: gql`
    mutation requestBulkAccess($fileId: Int!) {
      requestBulkAccess(fileId: $fileId)
    }
  `,
})

export const FetchFdaData = buildQuery<Pick<Query, 'fetchFdaData'>, QueryFetchFdaDataArgs>({
  query: gql`
    query fetchFdaData($input: FetchFdaInput!) {
      fetchFdaData(input: $input) {
        productQuantity
        recallNumber
        adverseEventCount
        approvalDate
        approvalRoute
      }
    }
  `,
})

export const SearchGmdnQuery = gql`
  query searchGmdn($searchText: String!) {
    searchGmdn(searchText: $searchText) {
      data {
        name
      }
    }
  }
`

export const SearchVendorQuery = gql`
  query searchVendor($searchText: String!) {
    searchVendor(searchText: $searchText) {
      data {
        name
      }
    }
  }
`

export const removeRecentSearchMutation = mutateFunction<
  Pick<Mutation, 'removeRecentSearch'>,
  MutationRemoveRecentSearchArgs
>({
  mutation: gql`
    mutation removeRecentSearch($searchId: String!) {
      removeRecentSearch(searchId: $searchId)
    }
  `,
  refetchQueries: ['SearchHistories'],
})

export const FetchClinicalStudiesData = buildQuery<
  Pick<Query, 'fetchClinicalStudies'>,
  QueryFetchClinicalStudiesArgs
>({
  query: gql`
    query fetchClinicalStudies(
      $input: FetchClinicalStudiesInput!
      $pagination: FetchClinicalStudiesPagination
    ) {
      fetchClinicalStudies(input: $input, pagination: $pagination) {
        data {
          nctId
          officialTitle
          completionDate
          studyType
          leadSponsorName
        }
        total
      }
    }
  `,
})
