export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: Date; output: Date; }
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
};

export type AlternativeHeadersInput = {
  CatalogNumber?: InputMaybe<Scalars['String']['input']>;
  PONumber?: InputMaybe<Scalars['String']['input']>;
  Price?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['String']['input']>;
  Supplier?: InputMaybe<Scalars['String']['input']>;
  Uom?: InputMaybe<Scalars['String']['input']>;
};

export type BadDataReportInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  partNumber?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  uom?: InputMaybe<Scalars['String']['input']>;
};

export type Benchmark = {
  __typename?: 'Benchmark';
  averagePrice?: Maybe<Scalars['Float']['output']>;
  hospitalCount?: Maybe<Scalars['Int']['output']>;
  iqo25?: Maybe<Scalars['Float']['output']>;
  iqo50?: Maybe<Scalars['Float']['output']>;
  iqo75?: Maybe<Scalars['Float']['output']>;
  iqo90?: Maybe<Scalars['Float']['output']>;
  priceSpray?: Maybe<Array<Maybe<PriceSpray>>>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  totalSpend?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkConfig = {
  bedCount?: InputMaybe<Range>;
  dateRange?: InputMaybe<Range>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type Billing = {
  __typename?: 'Billing';
  expireTime: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  searchCount: Scalars['Int']['output'];
  searchLimit: Scalars['Int']['output'];
};

export type BillingHistory = {
  __typename?: 'BillingHistory';
  createdAt: Scalars['Date']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  expireTime: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  searchCount: Scalars['Int']['output'];
  searchLimit: Scalars['Int']['output'];
};

export type BillingHistoryCollection = {
  __typename?: 'BillingHistoryCollection';
  data: Array<Maybe<BillingHistory>>;
  info: PageInfo;
};

export type ChangeFileNameInput = {
  fileId: Scalars['Int']['input'];
  newName: Scalars['String']['input'];
};

export type CheckFileHeaderResponse = {
  __typename?: 'CheckFileHeaderResponse';
  headers: Array<Scalars['String']['output']>;
  sample: Scalars['String']['output'];
};

export type ClinicalStudy = {
  __typename?: 'ClinicalStudy';
  completionDate?: Maybe<Scalars['String']['output']>;
  leadSponsorName?: Maybe<Scalars['String']['output']>;
  nctId: Scalars['String']['output'];
  officialTitle?: Maybe<Scalars['String']['output']>;
  studyType?: Maybe<Scalars['String']['output']>;
};

export type ClinicalStudyCollection = {
  __typename?: 'ClinicalStudyCollection';
  data: Array<ClinicalStudy>;
  total: Scalars['Int']['output'];
};

export type CreateBillingInput = {
  expireTime: Scalars['Date']['input'];
  searchLimit: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type CreateMappingInput = {
  CatalogNumber?: InputMaybe<Scalars['String']['input']>;
  PONumber?: InputMaybe<Scalars['String']['input']>;
  Price?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['String']['input']>;
  Supplier?: InputMaybe<Scalars['String']['input']>;
  Uom?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  signupCode?: InputMaybe<Scalars['String']['input']>;
};

export type CurvoInfo = {
  __typename?: 'CurvoInfo';
  productCategory?: Maybe<Scalars['String']['output']>;
};

export type DownloadInfo = {
  __typename?: 'DownloadInfo';
  downloadUrl: Scalars['String']['output'];
  fileId: Scalars['Int']['output'];
};

export type FalseNegativeInput = {
  match_id: Scalars['String']['input'];
  search_string?: InputMaybe<Scalars['String']['input']>;
  ts_id?: InputMaybe<Scalars['String']['input']>;
  uom: Scalars['String']['input'];
};

export type FdaData = {
  __typename?: 'FdaData';
  adverseEventCount?: Maybe<Scalars['Int']['output']>;
  approvalDate?: Maybe<Scalars['Date']['output']>;
  approvalRoute?: Maybe<Scalars['String']['output']>;
  productQuantity?: Maybe<Scalars['String']['output']>;
  recallNumber?: Maybe<Scalars['String']['output']>;
};

export type FetchClinicalStudiesInput = {
  gudid?: InputMaybe<Scalars['String']['input']>;
};

export type FetchClinicalStudiesPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type FetchFdaInput = {
  catalogNumber?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  submissionNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  versionModelNumber?: InputMaybe<Scalars['String']['input']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  numLines?: Maybe<Scalars['Int']['output']>;
  numMatchedLines?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileCollection = {
  __typename?: 'FileCollection';
  data: Array<Maybe<File>>;
  info: PageInfo;
};

export enum FileStatusEnum {
  Blocked = 'BLOCKED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Ready = 'READY',
  Uploading = 'UPLOADING'
}

export type FileStatusInfo = {
  __typename?: 'FileStatusInfo';
  downloadUrl: Scalars['String']['output'];
  numLines?: Maybe<Scalars['Int']['output']>;
  numMatchedLines?: Maybe<Scalars['Int']['output']>;
  status: FileStatusEnum;
};

export type FileUploadInput = {
  mimetype: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  name: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type GmdnCollection = {
  __typename?: 'GmdnCollection';
  data: Array<Maybe<GmdnItem>>;
};

export type GmdnItem = {
  __typename?: 'GmdnItem';
  name: Scalars['String']['output'];
};

export type Gudid = {
  __typename?: 'Gudid';
  catalogNumber?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  gmdnName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  gudid?: Maybe<Scalars['String']['output']>;
  submissionNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  versionModelNumber?: Maybe<Scalars['String']['output']>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type Mapping = {
  __typename?: 'Mapping';
  id: Scalars['Int']['output'];
  mapping: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  user: User;
};

export type Medline = {
  __typename?: 'Medline';
  medId: Scalars['String']['output'];
  unspsc?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  unspscInfo?: Maybe<UnspscInfo>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addRating: UserRating;
  changeFileName: File;
  checkHeaders: CheckFileHeaderResponse;
  createBilling: Billing;
  createFalseNegativeReport: Scalars['Boolean']['output'];
  createMapping: Mapping;
  deleteBilling: Scalars['Boolean']['output'];
  deleteMapping: Scalars['Boolean']['output'];
  generateDownloadUrl: DownloadInfo;
  generateUploadInfo: UploadInfo;
  inviteUser: User;
  manageUserInfo: User;
  processUploadedFile?: Maybe<Scalars['Boolean']['output']>;
  removeRecentFile: File;
  removeRecentSearch: Scalars['Boolean']['output'];
  removeUser: Scalars['Boolean']['output'];
  requestBulkAccess?: Maybe<Scalars['Boolean']['output']>;
  requestConstructAccess?: Maybe<Scalars['Boolean']['output']>;
  saveSearch: SavedSearch;
  sendBadDataReport?: Maybe<Scalars['Boolean']['output']>;
  shareSearch?: Maybe<Scalars['Boolean']['output']>;
  signUp: User;
  toggleBulkAccess: User;
  toggleConstructAccess: User;
  unblockUserFiles: Scalars['Boolean']['output'];
  updateBilling: Billing;
  updateMapping: Mapping;
  updateMe: User;
  userRequestIncreaseBilling: Billing;
  verifyAccount: User;
};


export type MutationAddRatingArgs = {
  model: RatingInput;
};


export type MutationChangeFileNameArgs = {
  data: ChangeFileNameInput;
};


export type MutationCheckHeadersArgs = {
  fileId: Scalars['Int']['input'];
};


export type MutationCreateBillingArgs = {
  input: CreateBillingInput;
};


export type MutationCreateFalseNegativeReportArgs = {
  data: Array<FalseNegativeInput>;
};


export type MutationCreateMappingArgs = {
  input: CreateMappingInput;
};


export type MutationDeleteBillingArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationDeleteMappingArgs = {
  id: Scalars['Int']['input'];
};


export type MutationGenerateDownloadUrlArgs = {
  fileId: Scalars['Int']['input'];
};


export type MutationGenerateUploadInfoArgs = {
  data: FileUploadInput;
};


export type MutationInviteUserArgs = {
  model: InviteUserInput;
};


export type MutationManageUserInfoArgs = {
  model?: InputMaybe<UpdateUserInput>;
  userId: Scalars['Int']['input'];
};


export type MutationProcessUploadedFileArgs = {
  input: ProcessFileArgs;
};


export type MutationRemoveRecentFileArgs = {
  fileId: Scalars['Int']['input'];
};


export type MutationRemoveRecentSearchArgs = {
  searchId: Scalars['String']['input'];
};


export type MutationRemoveUserArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationRequestBulkAccessArgs = {
  fileId: Scalars['Int']['input'];
};


export type MutationSaveSearchArgs = {
  data: SaveSearchInput;
};


export type MutationSendBadDataReportArgs = {
  data: BadDataReportInput;
};


export type MutationShareSearchArgs = {
  data: ShareSearchInput;
};


export type MutationSignUpArgs = {
  model: CreateUserInput;
};


export type MutationToggleBulkAccessArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationToggleConstructAccessArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationUnblockUserFilesArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationUpdateBillingArgs = {
  input: UpdateBillingInput;
};


export type MutationUpdateMappingArgs = {
  id: Scalars['Int']['input'];
  input: UpdateMappingInput;
};


export type MutationUpdateMeArgs = {
  model?: InputMaybe<UpdateUserInput>;
};


export type MutationVerifyAccountArgs = {
  args: VerifyAccountInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PriceSpray = {
  __typename?: 'PriceSpray';
  facilityid?: Maybe<Scalars['Int']['output']>;
  priceSpray?: Maybe<Array<Maybe<PriceSprayData>>>;
};

export type PriceSprayData = {
  __typename?: 'PriceSprayData';
  totalQtyPurchase?: Maybe<Scalars['Float']['output']>;
  unitprice?: Maybe<Scalars['Float']['output']>;
};

export type ProcessFileArgs = {
  alternativeHeaders?: InputMaybe<AlternativeHeadersInput>;
  enableConstructAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  fileId: Scalars['Int']['input'];
};

export type Product = {
  __typename?: 'Product';
  number?: Maybe<Scalars['String']['output']>;
  stripped?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  checkFileInfo: FileStatusInfo;
  fetchClinicalStudies: ClinicalStudyCollection;
  fetchFdaData: FdaData;
  getAllUsers: UserCollection;
  mappings: Array<Mapping>;
  me: User;
  ping?: Maybe<Scalars['String']['output']>;
  search: SearchCollection;
  searchBySharedSearch: SharedSearchData;
  searchFilter: SearchFilter;
  searchGmdn: GmdnCollection;
  searchVendor: VendorCollection;
  suggestion: Suggestion;
  user: User;
};


export type QueryCheckFileInfoArgs = {
  fileId: Scalars['Int']['input'];
};


export type QueryFetchClinicalStudiesArgs = {
  input: FetchClinicalStudiesInput;
  pagination?: InputMaybe<FetchClinicalStudiesPagination>;
};


export type QueryFetchFdaDataArgs = {
  input: FetchFdaInput;
};


export type QueryGetAllUsersArgs = {
  query?: InputMaybe<UserQueryInput>;
};


export type QuerySearchArgs = {
  pagination?: InputMaybe<Pagination>;
  query: SearchQueryInput;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QuerySearchBySharedSearchArgs = {
  searchHistoryId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchFilterArgs = {
  query?: InputMaybe<SearchQueryInput>;
  strict?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchGmdnArgs = {
  searchText: Scalars['String']['input'];
};


export type QuerySearchVendorArgs = {
  searchText: Scalars['String']['input'];
};


export type QuerySuggestionArgs = {
  searchText: Scalars['String']['input'];
  searchType: SearchTypeEnum;
};


export type QueryUserArgs = {
  userId: Scalars['Int']['input'];
};

export type Range = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type RatingInput = {
  context: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export enum RoleEnum {
  Admin = 'Admin',
  User = 'User'
}

export type SaveSearchInput = {
  name: Scalars['String']['input'];
  searchHistoryId: Scalars['String']['input'];
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SavedSearchCollection = {
  __typename?: 'SavedSearchCollection';
  data: Array<Maybe<SavedSearch>>;
  info: PageInfo;
};

export type SearchCollection = {
  __typename?: 'SearchCollection';
  data: Array<Maybe<SearchItem>>;
  info: PageInfo;
};

export type SearchFilter = {
  __typename?: 'SearchFilter';
  clinicalCategory: Array<Maybe<FilterItem>>;
  gic: Array<Maybe<FilterItem>>;
  gicType1: Array<Maybe<FilterItem>>;
  gicType2: Array<Maybe<FilterItem>>;
  gmdn: Array<Maybe<FilterItem>>;
  productCategory: Array<Maybe<FilterItem>>;
  productLine: Array<Maybe<FilterItem>>;
  segment: Array<Maybe<FilterItem>>;
  subSegment: Array<Maybe<FilterItem>>;
  supplier: Array<Maybe<FilterItem>>;
  totalResults: Scalars['Int']['output'];
  uom: Array<Maybe<FilterItem>>;
};

export type SearchHistory = {
  __typename?: 'SearchHistory';
  clinicalCategory?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exact?: Maybe<Scalars['Boolean']['output']>;
  fileId?: Maybe<Scalars['Int']['output']>;
  gic?: Maybe<Scalars['String']['output']>;
  gicType1?: Maybe<Scalars['String']['output']>;
  gicType2?: Maybe<Scalars['String']['output']>;
  gmdn?: Maybe<Scalars['String']['output']>;
  gudid?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  partialNumber?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<Scalars['String']['output']>;
  productLine?: Maybe<Scalars['String']['output']>;
  searchText: Scalars['String']['output'];
  segment?: Maybe<Scalars['String']['output']>;
  subSegment?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<SearchTypeEnum>;
  uom?: Maybe<Scalars['String']['output']>;
};

export type SearchHistoryCollection = {
  __typename?: 'SearchHistoryCollection';
  data: Array<Maybe<SearchHistory>>;
  info: PageInfo;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  batchId: Scalars['String']['output'];
  benchmark: Benchmark;
  conversion?: Maybe<Scalars['String']['output']>;
  curvo?: Maybe<CurvoInfo>;
  description?: Maybe<Scalars['String']['output']>;
  gudid?: Maybe<Gudid>;
  id?: Maybe<Scalars['String']['output']>;
  matchId?: Maybe<Scalars['String']['output']>;
  med?: Maybe<Medline>;
  perfectMatch: Scalars['String']['output'];
  product: Product;
  stan?: Maybe<StanType>;
  supplier: Supplier;
  transactionsList?: Maybe<Array<Transaction>>;
  trends?: Maybe<Trends>;
};

export type SearchQueryInput = {
  benchmarkConfig?: InputMaybe<BenchmarkConfig>;
  clinicalCategory?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exact?: InputMaybe<Scalars['Boolean']['input']>;
  gic?: InputMaybe<Scalars['String']['input']>;
  gicType1?: InputMaybe<Scalars['String']['input']>;
  gicType2?: InputMaybe<Scalars['String']['input']>;
  gmdn?: InputMaybe<Scalars['String']['input']>;
  gudid?: InputMaybe<Scalars['String']['input']>;
  partialNumber?: InputMaybe<Scalars['String']['input']>;
  productCategory?: InputMaybe<Scalars['String']['input']>;
  productLine?: InputMaybe<Scalars['String']['input']>;
  savedSearchId?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Scalars['String']['input']>;
  subSegment?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SearchTypeEnum>;
  uom?: InputMaybe<Scalars['String']['input']>;
};

export type SearchQuotaReport = {
  __typename?: 'SearchQuotaReport';
  searchCountTotal: Scalars['Int']['output'];
  searchLimitTotal: Scalars['Int']['output'];
};

export type SearchQuotaReportRange = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export enum SearchTypeEnum {
  Advanced = 'ADVANCED',
  Keyword = 'KEYWORD',
  Part = 'PART'
}

export type ShareSearchInput = {
  receiverEmail: Scalars['String']['input'];
  receiverFirstName: Scalars['String']['input'];
  receiverLastName: Scalars['String']['input'];
  searchLink: Scalars['String']['input'];
};

export type SharedSearchData = {
  __typename?: 'SharedSearchData';
  filters: SearchFilter;
  results: SearchCollection;
};

export type Sort = {
  asc?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
};

export type StanType = {
  __typename?: 'StanType';
  brand?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gicId?: Maybe<Scalars['Int']['output']>;
  gicName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['String']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  productLine?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  size1?: Maybe<Scalars['Int']['output']>;
  size2?: Maybe<Scalars['Int']['output']>;
  size3?: Maybe<Scalars['Int']['output']>;
  subSegment?: Maybe<Scalars['String']['output']>;
  typeOne?: Maybe<Scalars['String']['output']>;
  typeTwo?: Maybe<Scalars['String']['output']>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  suggestions: Array<Maybe<SuggestionGroup>>;
  text: Scalars['String']['output'];
};

export type SuggestionGroup = {
  __typename?: 'SuggestionGroup';
  data: Array<Maybe<SuggestionItem>>;
  groupType: SuggestionTypeEnum;
};

export type SuggestionItem = {
  __typename?: 'SuggestionItem';
  asp: Scalars['Float']['output'];
  benchmark: Benchmark;
  name: Scalars['String']['output'];
  supplier: Scalars['String']['output'];
  uom: Scalars['String']['output'];
};

export enum SuggestionTypeEnum {
  Product = 'Product',
  Supplier = 'Supplier',
  Uom = 'Uom'
}

export type Supplier = {
  __typename?: 'Supplier';
  name?: Maybe<Scalars['String']['output']>;
  normalized?: Maybe<Scalars['String']['output']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  datepurchase: Scalars['Int']['output'];
  facilityid: Scalars['Int']['output'];
  qtypurchase: Scalars['Float']['output'];
  tsId: Scalars['String']['output'];
  unitprice: Scalars['Float']['output'];
};

export type Trends = {
  __typename?: 'Trends';
  priceTrend?: Maybe<Scalars['Float']['output']>;
  volumeTrend?: Maybe<Scalars['Float']['output']>;
};

export type UnspscInfo = {
  __typename?: 'UnspscInfo';
  classTitle?: Maybe<Scalars['String']['output']>;
  commodityTitle?: Maybe<Scalars['String']['output']>;
  familyTitle?: Maybe<Scalars['String']['output']>;
  segmentTitle?: Maybe<Scalars['String']['output']>;
};

export type UpdateBillingInput = {
  expireTime?: InputMaybe<Scalars['Date']['input']>;
  searchLimit?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type UpdateMappingInput = {
  CatalogNumber?: InputMaybe<Scalars['String']['input']>;
  PONumber?: InputMaybe<Scalars['String']['input']>;
  Price?: InputMaybe<Scalars['String']['input']>;
  Quantity?: InputMaybe<Scalars['String']['input']>;
  Supplier?: InputMaybe<Scalars['String']['input']>;
  Uom?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadInfo = {
  __typename?: 'UploadInfo';
  fileId: Scalars['Int']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  billing?: Maybe<Billing>;
  billingHistories: BillingHistoryCollection;
  bulkAccess: Scalars['Boolean']['output'];
  constructAccess: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  files: FileCollection;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastLogin?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  role: RoleEnum;
  savedSearches: SavedSearchCollection;
  searchHistories: SearchHistoryCollection;
  searchQuotaReport?: Maybe<SearchQuotaReport>;
  signupCode?: Maybe<Scalars['String']['output']>;
};


export type UserBillingHistoriesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserFilesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserSavedSearchesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserSearchHistoriesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserSearchQuotaReportArgs = {
  range?: InputMaybe<SearchQuotaReportRange>;
};

export type UserCollection = {
  __typename?: 'UserCollection';
  info: PageInfo;
  users: Array<Maybe<User>>;
};

export type UserQueryInput = {
  pagination?: InputMaybe<Pagination>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type UserRating = {
  __typename?: 'UserRating';
  context: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type VendorCollection = {
  __typename?: 'VendorCollection';
  data: Array<Maybe<VendorItem>>;
};

export type VendorItem = {
  __typename?: 'VendorItem';
  name: Scalars['String']['output'];
};

export type VerifyAccountInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};
