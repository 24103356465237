import React, { RefObject, useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import numeral from 'numeral'
import {
  Maybe,
  SearchTypeEnum,
  SuggestionGroup,
  SuggestionItem as SuggestionItemType,
} from '../../api'
import { SearchFilterTotalResultsData, SuggestionData } from '../../api/components/Search'
import { useDebounce } from '../../hooks/useDebounce'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { Flex } from '../../shared/components/Flex'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors, FontWeight } from '../../shared/constants'
import { Actions } from '../../state/Actions'
import { useStateValue } from '../../state/State'

const Wrapper = styled(Flex)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`
const SuggestionList = styled(Flex)`
  width: 100%;
  padding-top: 12px;
  min-height: 48px;
  max-height: 250px;
  overflow-y: scroll;
`

const SuggestionGroupWrapper = styled(Flex)`
  flex: 1;
  width: 100%;
`
const GroupTitle = styled('span')`
  color: #6c8494;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 0 16px;
`
const SuggestionItem = styled(Flex)`
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: #f2f4f7;
  }
`

const ItemName = styled('span')`
  color: #344755;
  font-size: 16px;
  font-weight: medium;
  line-height: 19px;
  mark {
    background: transparent;
    font-weight: bold;
  }
  margin-bottom: 8px;
`
// const ItemTotal = styled('span')`
//   color: #516a7a;
//   font-size: 12px;
//   line-height: 14px;
// `
const ItemDetail = styled(Flex)`
  margin-right: 32px;
  color: #516a7a;
  font-size: 13px;
  width: 100px;
  span {
    font-weight: 500;
    margin-left: 6px;
  }
`
const NoMatch = styled('span')`
  padding: 0 16px;
  font-weight: ${FontWeight.medium};
`
const TotalResult = styled(Flex)`
  width: 100%;
  border-top: 1px solid #e0e7ed;
  font-weight: ${FontWeight.medium};
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background: #f2f4f7;
  }
`

const renderGroup =
  (searchText: string, onHandleClick: (item: SuggestionItemType) => void) =>
  (suggestionGroup: Maybe<SuggestionGroup>) => {
    if (!suggestionGroup) {
      return null
    }
    return (
      <SuggestionGroupWrapper key={suggestionGroup.groupType} column>
        <GroupTitle>{suggestionGroup.groupType}</GroupTitle>
        {suggestionGroup.data.length &&
          suggestionGroup.data.map((item, index) => {
            if (!item) {
              return null
            }

            return (
              <SuggestionItem
                key={`${item.name}-${index}`}
                column
                onClick={() => onHandleClick(item)}
              >
                <ItemName>
                  <Highlighter textToHighlight={item.name} searchWords={[searchText]} />
                </ItemName>
                <Flex>
                  <ItemDetail style={{ fontWeight: 500 }}>{item.supplier}</ItemDetail>
                  <ItemDetail>
                    UOM: <span> {item.uom}</span>
                  </ItemDetail>
                  <ItemDetail>
                    {item.benchmark &&
                    item.benchmark.hospitalCount &&
                    item.benchmark.hospitalCount > 1 ? (
                      <>
                        Average: <span> ${numeral(item.asp).format('0,0.00')}</span>
                      </>
                    ) : (
                      <>Low volume</>
                    )}
                  </ItemDetail>
                </Flex>
              </SuggestionItem>
            )
          })}
      </SuggestionGroupWrapper>
    )
  }

type Props = {
  searchText: string
  searchType: SearchTypeEnum
  on: boolean
  off: () => void
  parentRef: RefObject<HTMLDivElement>
  onItemClick: (item: SuggestionItemType) => void
} & RouteComponentProps

const SuggestionDropdown: React.FC<Props> = ({
  searchText,
  searchType,
  on,
  off,
  parentRef,
  onItemClick,
  history,
}) => {
  const debounced = useDebounce(searchText, 300)
  const [, dispatch] = useStateValue()

  const ref = useRef(null)

  useOnClickOutside(ref, e => {
    if (
      e.target &&
      parentRef.current &&
      !parentRef.current.contains(e.target as HTMLElement) &&
      on
    ) {
      off()
    }
  })

  if (debounced.length <= 1) {
    return null
  }

  const onTotalClick = (searchTextString: string) => {
    dispatch({ payload: searchTextString, type: Actions.ChangeSearchTerm })
    history.push('/search', { name: searchTextString })
  }

  return (
    <Wrapper ref={ref} column>
      <SuggestionList>
        <SuggestionData variables={{ searchText: debounced, searchType }}>
          {({ data, loading }) => {
            if (loading) {
              return (
                <Flex style={{ position: 'absolute', right: '32px', top: '16px' }}>
                  <LoadingIndicator color={Colors.primary} />
                </Flex>
              )
            }
            if (data && data.suggestion && !data.suggestion.suggestions.length) {
              return <NoMatch>No match</NoMatch>
            }
            return (
              data &&
              data.suggestion &&
              data.suggestion.suggestions.map(renderGroup(searchText, onItemClick))
            )
          }}
        </SuggestionData>
      </SuggestionList>
      <SearchFilterTotalResultsData variables={{ query: { searchText, type: searchType } }}>
        {({ data, loading }) => {
          if (loading) {
            return <TotalResult>Loading results...</TotalResult>
          }
          if (!data || data.searchFilter.totalResults <= 1) {
            return null
          }
          return (
            <TotalResult onClick={() => onTotalClick(searchText)}>
              See all {new Intl.NumberFormat('en-US').format(data.searchFilter.totalResults)}{' '}
              results for {`"${searchText}"`}
            </TotalResult>
          )
        }}
      </SearchFilterTotalResultsData>
    </Wrapper>
  )
}
export default withRouter(SuggestionDropdown)
