import { FormikProps } from 'formik'
import React, { useState, useEffect, useContext, CSSProperties } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import {
  SearchFilterData,
  SearchFilterTotalResultsData,
  SearchGmdnQuery,
  SearchVendorQuery,
} from '../../api/components/Search'
import { Button } from '../../shared/components/Button'
import { Flex } from '../../shared/components/Flex'
import TextInput from '../../shared/components/Inputs/TextInput'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors, FontWeight } from '../../shared/constants'
import { ApolloClientContext, GmdnItem, VendorItem } from '../../api'
import { useDebounce } from '../../hooks/useDebounce'
import { Values } from './AdvanceSearch'

const Wrapper = styled('form')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`
const ResultText = styled('p')`
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #8d9da6;
  min-height: 16px;
  font-weight: ${FontWeight.medium};

  span {
    color: #000000;
    font-weight: ${FontWeight.bold};
  }
`
const StyledLoadingIndicator = styled('div')`
  margin-top: 20px;
  margin-bottom: 24px;
`

const ErrorText = styled('p')`
  margin-top: 20px;
  font-size: 16px;
  color: red;
  font-weight: ${FontWeight.medium};
`

const placeHolderStyles = (base: CSSProperties): CSSProperties => ({
  ...base,
  color: '#C9D4DD',
  fontWeight: FontWeight.bold,
})

const indicatorSeparatorStyles = (base: CSSProperties): CSSProperties => ({
  ...base,
  display: 'none',
})

const baseContainerStyles = (base: CSSProperties): CSSProperties => ({
  ...base,
  flex: 1,
  height: '50px',
})

const controlStyles = (base: CSSProperties, state: any): CSSProperties => ({
  ...base,
  height: '100%',
  background: '#F2F4F7',
  borderRadius: 4,
  border: 'none',
  padding: '4px',
  boxShadow: state.isFocused && `0 0 0 1px ${Colors.primary}`,
})

const rightContainerStyles = (base: CSSProperties): CSSProperties => {
  const customBase = baseContainerStyles(base)
  return {
    ...customBase,
    marginRight: '8px',
  }
}

type Props = FormikProps<Values>

const AdvanceSearchForm: React.FC<Props> = ({
  values,
  handleSubmit,
  setFieldValue,
  isSubmitting,
}) => {
  const [filterQuery, setFilterQuery] = useState({})
  const { client } = useContext(ApolloClientContext)

  const debouncedFilterQuery = useDebounce(filterQuery, 500)

  useEffect(() => {
    setFilterQuery({
      supplier: values.supplier,
      uom: values.uom,
      partialNumber: values.partial,
      segment: values.segment,
      subSegment: values.subSegment,
      gic: values.gic,
      gicType1: values.gicType1,
      gicType2: values.gicType2,
      productLine: values.productLine,
      productCategory: values.productCategory,
      clinicalCategory: values.clinicalCategory,
      description: values.description,
      gmdn: values.gmdn,
      gudid: values.gudid,
    })
  }, [values])

  const [atLeastOne, setAtLeastOne] = useState(false)
  const [erroMsg, setErroMsg] = useState<string>()
  useEffect(() => {
    const arr = Object.values(filterQuery).filter(val => val !== null)
    if (arr.length) {
      setAtLeastOne(true)
    }
  }, [filterQuery])

  const validateSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (atLeastOne) {
      handleSubmit()
    } else {
      setErroMsg('At least one parameter should be chosen')
    }
  }

  const gmdnOptions = async (input: string) => {
    const result = await client.query({ query: SearchGmdnQuery, variables: { searchText: input } })
    const gmdns = result.data.searchGmdn.data.map((item: GmdnItem) => {
      return { value: item.name, label: item.name }
    })

    return gmdns
  }

  const vendorOptions = async (input: string) => {
    const result = await client.query({
      query: SearchVendorQuery,
      variables: { searchText: input },
    })
    const vendors = result.data.searchVendor.data.map((item: VendorItem) => {
      return { value: item.name, label: item.name }
    })

    return vendors
  }

  return (
    <Wrapper onSubmit={validateSubmit}>
      <SearchFilterData>
        {({ data, loading }) => (
          <>
            <Flex fullWidth>
              <AsyncSelect
                autoFocus
                name="supplier"
                isLoading={loading}
                value={
                  values.supplier ? { label: values.supplier, value: values.supplier } : undefined
                }
                placeholder="Vendor / Manufacturer"
                isClearable
                loadOptions={vendorOptions}
                noOptionsMessage={() => 'No Vendor'}
                onChange={(value: any) => {
                  setFieldValue('supplier', value && value.value)
                }}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: baseContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
            </Flex>
            <Flex fullWidth marginTop="1rem">
              <Select
                placeholder="UOM"
                isLoading={loading}
                isClearable
                value={values.uom ? { label: values.uom, value: values.uom } : undefined}
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.uom.map(uom => ({
                    label: uom && uom.name,
                    value: uom && uom.name,
                  }))
                }
                noOptionsMessage={() => 'No UOM'}
                onChange={(value: any) => setFieldValue('uom', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: rightContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
              <Flex flex={5} alignSelf="stretch">
                <TextInput
                  inputProps={{
                    type: 'text',
                    placeholder: 'Catalog Number',
                    value: values.partial || '',
                    onChange: e => setFieldValue('partial', e.target.value),
                  }}
                  style={{ marginRight: '8px' }}
                />
              </Flex>
              <Flex flex={5} alignSelf="stretch">
                <TextInput
                  inputProps={{
                    type: 'text',
                    placeholder: 'GTIN',
                    value: values.gudid || '',
                    onChange: e => setFieldValue('gudid', e.target.value),
                  }}
                />
              </Flex>
            </Flex>
            <Flex fullWidth marginTop="1rem">
              <AsyncSelect
                placeholder="GMDN"
                isLoading={loading}
                isClearable
                value={values.gmdn ? { label: values.gmdn, value: values.gmdn } : undefined}
                loadOptions={gmdnOptions}
                noOptionsMessage={val =>
                  val.inputValue
                    ? `No options for "${val.inputValue}"`
                    : 'Start typing the desired GMDN'
                }
                onChange={(value: any) => setFieldValue('gmdn', value && value.value)}
                className="uppercase-input"
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: baseContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                  noOptionsMessage: base => ({
                    ...base,
                    textAlign: 'left',
                  }),
                }}
              />
            </Flex>
            <Flex fullWidth marginTop="1rem">
              <Select
                placeholder="GIC"
                isLoading={loading}
                isClearable
                value={values.gic ? { label: values.gic, value: values.gic } : undefined}
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.gic.map(gic => ({
                    label: gic && gic.name,
                    value: gic && gic.name,
                  }))
                }
                noOptionsMessage={() => 'No GIC options'}
                onChange={(value: any) => setFieldValue('gic', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: rightContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
              <Select
                placeholder="GIC Type 1"
                isLoading={loading}
                isClearable
                value={
                  values.gicType1 ? { label: values.gicType1, value: values.gicType1 } : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.gicType1.map(gicType1 => ({
                    label: gicType1 && gicType1.name,
                    value: gicType1 && gicType1.name,
                  }))
                }
                noOptionsMessage={() => 'No GIC Type 1 options'}
                onChange={(value: any) => setFieldValue('gicType1', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: rightContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
              <Select
                placeholder="GIC Type 2"
                isLoading={loading}
                isClearable
                value={
                  values.gicType2 ? { label: values.gicType2, value: values.gicType2 } : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.gicType2.map(gicType2 => ({
                    label: gicType2 && gicType2.name,
                    value: gicType2 && gicType2.name,
                  }))
                }
                noOptionsMessage={() => 'No GIC Type 2 options'}
                onChange={(value: any) => setFieldValue('gicType2', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: baseContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
            </Flex>
            {/*<Flex fullWidth marginTop="1rem">
              <Select
                placeholder="Product Category"
                isLoading={loading}
                isClearable
                value={
                  values.productCategory
                    ? { label: values.productCategory, value: values.productCategory }
                    : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.productCategory.map(productCategory => ({
                    label: productCategory && productCategory.name,
                    value: productCategory && productCategory.name,
                  }))
                }
                noOptionsMessage={() => 'No categories'}
                onChange={(value: any) => setFieldValue('productCategory', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: rightContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
              <Select
                placeholder="Clinical Category"
                isLoading={loading}
                isClearable
                value={
                  values.clinicalCategory
                    ? { label: values.clinicalCategory, value: values.clinicalCategory }
                    : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.clinicalCategory.map(clinicalCategory => ({
                    label: clinicalCategory && clinicalCategory.name,
                    value: clinicalCategory && clinicalCategory.name,
                  }))
                }
                noOptionsMessage={() => 'No categories'}
                onChange={(value: any) => setFieldValue('clinicalCategory', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: baseContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
            </Flex>*/}
            <Flex fullWidth marginTop="1rem">
              <Select
                placeholder="Segment"
                isLoading={loading}
                isClearable
                value={
                  values.segment ? { label: values.segment, value: values.segment } : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.segment.map(segment => ({
                    label: segment && segment.name,
                    value: segment && segment.name,
                  }))
                }
                noOptionsMessage={() => 'No segments'}
                onChange={(value: any) => setFieldValue('segment', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: rightContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
              <Select
                placeholder="Sub Segment"
                isLoading={loading}
                isClearable
                value={
                  values.subSegment
                    ? { label: values.subSegment, value: values.subSegment }
                    : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.subSegment.map(subSegment => ({
                    label: subSegment && subSegment.name,
                    value: subSegment && subSegment.name,
                  }))
                }
                noOptionsMessage={() => 'No sub segments'}
                onChange={(value: any) => setFieldValue('subSegment', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: baseContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
            </Flex>
            <Flex fullWidth marginTop="1rem">
              <Select
                placeholder="Product Line"
                isLoading={loading}
                isClearable
                value={
                  values.productLine
                    ? { label: values.productLine, value: values.productLine }
                    : undefined
                }
                options={
                  data &&
                  data.searchFilter &&
                  data.searchFilter.productLine.map(productLine => ({
                    label: productLine && productLine.name,
                    value: productLine && productLine.name,
                  }))
                }
                noOptionsMessage={() => 'No product line options'}
                onChange={(value: any) => setFieldValue('productLine', value && value.value)}
                styles={{
                  indicatorSeparator: indicatorSeparatorStyles,
                  container: baseContainerStyles,
                  control: controlStyles,
                  placeholder: placeHolderStyles,
                }}
              />
            </Flex>
            <Flex fullWidth marginTop="1rem">
              <Flex flex={2} alignSelf="stretch">
                <TextInput
                  inputProps={{
                    type: 'text',
                    placeholder: 'Description',
                    value: values.description || '',
                    onChange: e => setFieldValue('description', e.target.value),
                  }}
                />
              </Flex>
            </Flex>
            <Flex fullWidth justify="space-between">
              <SearchFilterTotalResultsData variables={{ query: debouncedFilterQuery }}>
                {({ data: totalResultsData, loading: totalResultsLoading }) => {
                  if (totalResultsLoading) {
                    return (
                      <StyledLoadingIndicator>
                        <LoadingIndicator center color={Colors.primary} />
                      </StyledLoadingIndicator>
                    )
                  }
                  if (!totalResultsData) {
                    return null
                  }
                  return (
                    <ResultText>
                      {atLeastOne && (
                        <>
                          Your search has{' '}
                          <span>
                            {new Intl.NumberFormat('en-US').format(
                              totalResultsData.searchFilter.totalResults,
                            )}
                          </span>{' '}
                          result(s)
                        </>
                      )}
                    </ResultText>
                  )
                }}
              </SearchFilterTotalResultsData>
              {erroMsg && <ErrorText>{erroMsg}</ErrorText>}
            </Flex>
          </>
        )}
      </SearchFilterData>
      <Button disabled={isSubmitting} type="submit">
        {isSubmitting ? <LoadingIndicator /> : 'Show Results'}
      </Button>
    </Wrapper>
  )
}

export default AdvanceSearchForm
