import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Flex } from '../../shared/components/Flex'
import { Colors } from '../../shared/constants'

const ROUTES = [
  {
    id: 1,
    name: 'User',
    route: '/user',
  },
]

const Wrapper = styled(Flex as any)`
  width: 100%;
`

const StyledNavLink = styled(NavLink)`
  width: 100%;
  padding: 12px 32px;
  font-size: 16px;
`

const MenuItem: React.FC<{ name: string; route: string }> = ({ name, route }) => (
  <StyledNavLink
    activeStyle={{
      borderRight: `3px solid ${Colors.primary}`,
      background: rgba(Colors.primary, 0.2),
      color: Colors.primary,
    }}
    to={`/admin${route}`}
  >
    {name}
  </StyledNavLink>
)

type Props = {}

const AdminSidebar: React.FC<Props> = () => (
  <Wrapper>
    {ROUTES.map(item => (
      <MenuItem key={item.id} name={item.name} route={item.route} />
    ))}
  </Wrapper>
)

export default AdminSidebar
