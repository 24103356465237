import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Flex } from '../../shared/components/Flex'
import { SearchHistoriesData, SearchHistory, SearchTypeEnum, ApolloClientContext } from '../../api'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import { useStateValue } from '../../state/State'
import { Actions } from '../../state/Actions'
import { ReactComponent as DeleteIcon } from '../../assets/img/delete.svg'
import { removeRecentSearchMutation } from '../../api/components/Search'

import { ItemDesc, ItemTitle, RecentItem } from './shared/RecentItem'
import { RecentTitle } from './shared/RecentTitle'

const Wrapper = styled(Flex)`
  flex: 1;
  max-width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
`

const SearchList = styled('div')``

const Tooltip = styled(Flex)`
  position: absolute;
  left: 0;
  bottom: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 8px 12px;
  width: max-content;
  span {
    font-weight: 600;
  }
  div {
    margin: 4px;
  }
  display: none;
`

const WrappedRecentItem = styled(RecentItem)`
  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
  width: max-content;
`

const StyledDeleteIcon = styled(DeleteIcon)`
  width: 10px;
  position: absolute;
  right: 8px;
  top: 8px;
  &:hover {
    color: ${Colors.danger};
  }
`

const getItemTitle = (title: string | undefined, type: SearchTypeEnum | null | undefined) => {
  if (!title) {
    return 'Advanced'
  }
  const titleArr = title.split(' ')
  return titleArr.length > 1
    ? `Multi-${type && type === SearchTypeEnum.Keyword ? 'Keyword' : 'SKU'}`
    : title
}

type Props = {} & RouteComponentProps

const RecentSearch: React.FC<Props> = ({ history }) => {
  const [, dispatch] = useStateValue()
  const { client } = useContext(ApolloClientContext)
  const [deletingId, setDeletingId] = useState('')

  const handleClick = (item: SearchHistory) => {
    // if simple search
    dispatch({ payload: item.searchText, type: Actions.ChangeSearchTerm })
    history.push('/search', {
      name: item.searchText,
      supplier: item.supplier,
      uom: item.uom,
      partial: item.partialNumber,
      segment: item.segment,
      subSegment: item.subSegment,
      gic: item.gic,
      gicType1: item.gicType1,
      gicType2: item.gicType2,
      productLine: item.productLine,
      productCategory: item.productCategory,
      clinicalCategory: item.clinicalCategory,
      gmdn: item.gmdn,
      gudid: item.gudid,
      type: item.type,
      exact: item.exact,
    })
  }

  const tooltipCategories = (item: SearchHistory) => {
    let numCategories = 0

    const allCategories = [
      item.segment,
      item.subSegment,
      item.gic,
      item.gicType1,
      item.gicType2,
      item.productLine,
      item.productCategory,
      item.clinicalCategory,
      item.gmdn,
      item.gudid,
    ]

    numCategories = allCategories.filter(cat => !!cat).length
    if (numCategories > 0) {
      return (
        <div>
          Category:{' '}
          <span>{numCategories === 1 ? '1 Category' : `${numCategories} categories`}</span>
        </div>
      )
    }
    return null
  }
  return (
    <Wrapper>
      <SearchHistoriesData>
        {({ loading, data }) => {
          if (loading && (!data || !data.me)) {
            return <LoadingIndicator color={Colors.primary} />
          }
          if (!data || !data.me.searchHistories || !data.me.searchHistories.data) {
            return 'No recent searches'
          }
          return (
            <>
              <RecentTitle>Recent Searches</RecentTitle>
              <SearchList>
                {data.me.searchHistories.data.slice(0, 10).map(item => {
                  if (!item) {
                    return null
                  }
                  const numMultiSku = item.searchText.split(' ').length
                  const showTooltip =
                    !!item.supplier ||
                    !!item.uom ||
                    !!item.partialNumber ||
                    !!item.segment ||
                    !!item.subSegment ||
                    !!item.gic ||
                    !!item.gicType1 ||
                    !!item.gicType2 ||
                    !!item.productLine ||
                    !!item.productCategory ||
                    !!item.clinicalCategory ||
                    !!item.gmdn ||
                    !!item.gudid ||
                    numMultiSku > 1
                  return (
                    <WrappedRecentItem
                      key={item.id}
                      onClick={() => handleClick(item)}
                      style={{ position: 'relative', paddingRight: 32 }}
                    >
                      {deletingId === item.id ? (
                        <LoadingIndicator
                          color={Colors.primary}
                          size={10}
                          style={{ position: 'absolute', right: 8, top: 8 }}
                        />
                      ) : (
                        <StyledDeleteIcon
                          onClick={async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                            e.stopPropagation()
                            setDeletingId(item.id)
                            await removeRecentSearchMutation(client, { searchId: item.id })
                          }}
                        />
                      )}

                      <ItemTitle>{getItemTitle(item.searchText, item.type)}</ItemTitle>
                      <ItemDesc>
                        {item.totalResults
                          ? new Intl.NumberFormat('en-US').format(item.totalResults)
                          : 0}{' '}
                        results
                      </ItemDesc>
                      {showTooltip && (
                        <Tooltip column>
                          {numMultiSku > 1 && (
                            <div>
                              <span>{item.searchText.split(' ').length}</span> SKUs
                            </div>
                          )}
                          {!(numMultiSku > 1) && item.supplier && (
                            <div>
                              Supplier: <span>{item.supplier}</span>
                            </div>
                          )}
                          {!(numMultiSku > 1) && item.uom && (
                            <div>
                              UOM: <span>{item.uom}</span>
                            </div>
                          )}
                          {!(numMultiSku > 1) && item.partialNumber && (
                            <div>
                              Part Number: <span>{item.partialNumber}</span>
                            </div>
                          )}
                          {tooltipCategories(item)}
                        </Tooltip>
                      )}
                    </WrappedRecentItem>
                  )
                })}
              </SearchList>
            </>
          )
        }}
      </SearchHistoriesData>
    </Wrapper>
  )
}

export default withRouter(RecentSearch)
